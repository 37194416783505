<template lang="">
  <div>
    <div v-if="!isLoaded" class="h-screen vld-parent">
      <loading :active="!isLoaded" :is-full-page="false" />
    </div>
    <template v-if="isLoaded">
      <div
        class="grid lg:grid-cols-2 lg:space-x-2 md:space-x-0 md:space-y-2 md:grid-cols-1"
      >
        <info-grid
          grid-class="grid-cols-1"
          :title="$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.profileInfo.headline')"
          :data="getProfile()"
        />
        <info-grid
          grid-class="grid-cols-1"
          :title="$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.headline')"
          :data="getBillingData()"
        />
      </div>
    </template>
  </div>
</template>
<script>
import InfoGrid from '@/components/layout/InfoGrid'
import { MarketplaceConfig } from '@/config/MarketplaceConfig'
import { getFormattedDateTime } from '@/utils/datetime'
export default {
  name: 'ViewBuyerProfile',
  components: {
    InfoGrid,
  },
  data() {
    return {
      isLoaded: false,
      profileData: {},
      fallbackText: '--',
      lockManufacturers: [],
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {
    await this.$http
      .get(MarketplaceConfig.api.marketplaceBuyerProfile(this.id))
      .then((res) => {
        this.isLoaded = true
        this.profileData = res.data.data
        console.log('profileData', this.profileData)
      })
  },
  methods: {
    getFormattedDateTime,

    getProfile() {
      let profile = []
      profile.push({
        text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.profileInfo.title.profileCategory'),
        value: this.profileData.profile_category,
      })
      profile.push({
       text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.profileInfo.title.companyName'),
        value: this.profileData.company_name,
      })
      profile.push({
         text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.profileInfo.title.firstName'),
        value: this.profileData.user.first_name,
      })
      profile.push({
       text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.profileInfo.title.lastName'),
        value: this.profileData.user.last_name,
      })
      profile.push({
     text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.profileInfo.title.email'),
        value: this.profileData.user.email,
      })

      return profile
    },
    getBillingData() {
      let billInfo = []
      billInfo.push({
        text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.billingCategory'),
        value: this.profileData.billing.billing_category,
      })
      billInfo.push({
        text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.legalName'),
        value: this.profileData.billing.legal_name,
      })
      billInfo.push({
         text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.email'),
        value: this.profileData.billing.email,
      })
      billInfo.push({
          text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.address'),
        value: this.profileData.billing.address,
      })
      billInfo.push({
          text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.city'),
        value: this.profileData.billing.city,
      })
      billInfo.push({
          text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.state'),
        value: this.profileData.billing.state,
      })
      billInfo.push({
          text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.zipCode'),
        value: this.profileData.billing.zip_code,
      })
      billInfo.push({
         text: this.$t('components.marketPlaceManagement.buyers.details.buyerManagement.steps.profile.billingInfo.title.vATIdNumber'),
        value: this.profileData.billing.vat_id_number,
      })

      return billInfo
    },
  },
}
</script>
<style lang=""></style>
